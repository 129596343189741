import React from "react"

const contactDetails = {
  name: "Forwardsafe Designs",
  number: "+447391152520",
  whatsappNumber: "447391152520",
  numberDisplay: "+44 7391 152520",
  email: "designs@forwardsafe.com",
  address: {
    name: "",
    street: "",
    city: "",
    postcode: "",
  },
}
// website
// app
// graphics
// technicalSupport
// scripting
// marketing
// realWorld
const data = {
  long: {
    aboutUs: ``,
  },
  short: {
    // Who are we
    aboutUs: <></>,
  },
  portfolio: [
    {
      serviceKey: "website",
      title: "Websites",
      subtitle: "Built with perfect SEO & Performance",
      miniCard: true,
      items: [
        {
          name: "Stepiconic",
          description: "Iconic Clothing",
          previewImage: "stepiconic-mck",
          links: ["https://www.stepiconic.co.uk/"],
        },
        // {
        //   name: "HD Kitz",
        //   description: "",
        // },
        {
          name: "Halal Online Income",
          description: "The #1 Platform for Muslim Entrepreneurs",
          previewImage: "hoi-mbp",
          featured: true,
          links: ["https://www.halalonlineincome.com"],
        },
        {
          name: "Scratch Fix Body Repairs",
          description: "",
          previewImage: "sbr",
          links: ["https://www.scratchfixbodyrepairs.com/"],
        },
        {
          name: "Sweet Stop UK",
          description: "",
          previewImage: "Sweetstop",
          links: ["https://www.sweetstopuk.co.uk"],
        },
        {
          name: "Mosque Kitchen",
          description: "",
          previewImage: "msk-mockup",
          links: ["https://www.mosquekitchen.com"],
        },
        {
          name: "Asyris",
          description: "",
          previewImage: "asyris-mock",
          links: ["https://www.asyris-store.com"],
        },

        {
          name: "Aynetree Guest House",
          description: "",
          previewImage: "agh",
          links: ["https://www.aynetreeguesthouse.com/"],
        },
        {
          name: "Forwardsafe",
          description: "",
          previewImage: "fs",
          links: ["https://www.forwardsafe.co.uk"],
        },
        {
          name: "Scottish Muslim Funeral Services",
          description: "",
          previewImage: "smfs-mock",
          featured: true,
          links: ["https://www.scottishmuslimfuneralservices.co.uk/"],
        },
        {
          name: "Emirates Adventure",
          description: "",
          previewImage: "ea-mock",
          links: ["https://emirates-adventure.com/"],
        },
      ],
    },
    {
      serviceKey: "graphics",
      title: "Graphic Design",

      subTypes: [
        {
          serviceKey: "logo",
          title: "Logo",
          miniCard: true,
          items: [
            {
              name: "Asyris",
              description: "",
              previewImage: "logo-asyris",
              links: ["https://www.asyris-store.com/"],
            },
            {
              name: "Aynetree Guest House",
              description: "",
              previewImage: "logo-agh",
              links: ["https://www.aynetreeguesthouse.com/"],
            },
            // {
            //   name: "At-Taaj Al-fareed",
            //   description: "",
            //   previewImage: "logo-ataf",
            // },
            {
              name: "Barber Waqz",
              previewImage: "logo-barberwaqz",
              description: "",
            },
            {
              name: "HD Kitz",
              description: "",
              previewImage: "logo-hdkitz",
              links: ["https://www.hdkitz.co.uk"],
            },
            {
              name: "Stepiconic",
              description: "",
              previewImage: "logo-stepiconic",
              featured: true,
              links: ["https://www.stepiconic.co.uk"],
            },
            {
              name: "Sweet Stop UK",
              description: "",
              previewImage: "logo-sweetstop",
              links: ["https://www.sweetstopuk.co.uk/"],
            },
            {
              name: "Double Time Recruitment",
              description: "",
              previewImage: "logo-dtr",
            },
            {
              name: "Forwardsafe",
              description: "",
              previewImage: "logo-fs",
              links: ["https://www.forwardsafe.co.uk"],
            },

            {
              name: "Forwardsafe Designs",
              description: "",
              previewImage: "logo-fsd",
              links: ["https://designs.forwardsafe.co.uk"],
            },
            {
              name: "Halal Online Income",
              description: "",
              previewImage: "logo-hoi",
              links: ["https://www.halalonlineincome.com"],
            },
            {
              name: "My Trading Algo",
              description: "",
              previewImage: "logo-mta",
            },
            {
              name: "Yung Sparkz",
              description: "",
              previewImage: "logo-yz",
              links: ["https://www.youtube.com/@qasimmalik4031"],
            },
            {
              name: "AKH Garmz",
              description: "",
              previewImage: "logo-akhgz",
              links: ["https://instagram.com/akhgarmz"],
            },
          ],
        },
        {
          serviceKey: "animation",
          title: "Animations",
          // useModal: true,
          // miniCard: true,
          items: [
            {
              name: "Halal Online Income",
              description: "",
            },
            {
              name: "HD Kitz Launch",
              description: "",
            },
            {
              name: "Promo",
              description: "",
            },
            {
              name: "Stepiconic",
              description: "",
            },
          ],
        },
        {
          serviceKey: "businessCards",
          title: "Business Cards",
          subtitle: "Designed & Printed",
          // useModal: true,
          miniCard: true,
          items: [
            {
              name: "Aynetree Guest House",
              description: "",
              previewImage: "businesscard-agh",
            },
            // {
            //   name: "Estate Lens",
            //   description: "",

            // },
            {
              name: "HD Kitz",
              description: "",
              previewImage: "businesscard-hdkitz",
            },
            {
              name: "Stepiconic",
              description: "",
              previewImage: "businesscard-stepiconic",
            },
            {
              name: "Wedding Favours",
              description: "",
              featured: true,
              previewImage: "businesscard-weddingfavour",
            },
            {
              name: "Chocolate Favour",
              description: "",
              previewImage: "businesscard-weddingfavour2",
            },
          ],
        },
        {
          serviceKey: "leaflet",
          title: "Leaflets",
          subtitle: "Designed & Printed",
          // useModal: true,
          miniCard: true,
          items: [
            {
              name: "Mosque Kitchen Table Menu",
              description: "",
              previewImage: "leaflets-msk",
            },
            {
              name: "Wedding Cards",
              description:
                "Complete Design, Physical and Virtual Cards generated with any names",
              previewImage: "leaflets-weddingcard2",
              featured: true,
            },
            {
              name: "Wedding E-Cards",
              description:
                "Complete Design, Physical and Virtual Cards generated with any names",
              previewImage: "leaflets-weddingcard1",
            },
            {
              name: "Auto Pristine Leaflet",
              description: "",
              previewImage: "leaflets-ap1",
              featured: true,
              links: ["https://instagram.com/autopristinedetailingg"],
            },
          ],
        },
        {
          serviceKey: "displayBoards",
          title: "Display Boards",
          subtitle: "Designed, Printed & Fitted",
          items: [
            {
              name: "Shish Edinburgh",
              description: "Display Screen",
            },
            {
              name: "Mosque Kitchen Menu",
              description: "",
            },
            {
              name: "Aynetree Guest House Entrance Sign",
              description: "",
            },
            {
              name: "Stepiconic Banner",
              description: "",
            },
          ],
        },
      ],
      items: [],
    },
    {
      serviceKey: "technicalSupport",
      title: "Technical Support",
      items: [
        {
          type: "technicalSupport",
          subtype: "web",
          name: "Website Hosting",
          description:
            "We have extensive experience in customised website hosting to maximite the speed and efficiency of your website regardless of what technology was used to build it",
        },
        {
          type: "technicalSupport",
          subtype: "web",
          name: "Website Recovery",
          description:
            "In an evolving world older services and hosting companies are becoming obselete and clients are loosing their websites, we specialise in recovering any given web site & providing and setting up alternative hosting to fit modern standards along with backups for the future, without any interaction with the orignal hosting company",
        },
        {
          type: "technicalSupport",
          subtype: "apparel",
          name: "Custom Designed Masks",
          description: "",
        },
        {
          type: "technicalSupport",
          subtype: "apparel",
          name: "Custom Jumpers & T-Shirts",
          description: "",
        },
        {
          type: "technicalSupport",
          subtype: "content",
          name: "Video Editing",
          description: "From YouTube to TikTok to Kiosk Displays we do it all!",
        },
      ],
    },
    {
      serviceKey: "realWorld",
      title: "Our Real World Ventures",
      items: [
        {
          subtype: "business",
          name: "Kiosk DIsplay Complete Set Up",
          description:
            "Complete setup for singular screen with no media software supplied & fitted",
        },
        {
          subtype: "car",
          name: "OEM style Headunit + Reverse Camera Retrofit",
          description: "",
        },
        {
          subtype: "mobile",
          name: "",
          description: "",
        },
      ],
    },
  ],
  testimonials: [],
  services: [
    {
      name: "Website Design and Development",
      description:
        "Stunning responsive websites optimized for speed and conversion. Our full-stack developers expertly craft custom sites using modern frameworks - React, Angular, and VueJS.",
      previewImage: "webdesign",
      key: "website",
    },
    {
      name: "Mobile App Development",
      description:
        "Secure, Scalable, High-performing native iOS and Android apps. Expertise in Kotlin, Swift, Flutter, and React Natives",
      previewImage: "mobileapp1",
      key: "app",
    },
    {
      name: "Search Engine Optimisation",
      description:
        "Enhance your digital appearance, Expand your Audience, Grow your business!",
      previewImage: "seo2",
      key: "seo",
    },
    {
      name: "Logo Design",
      description: "",
      shortList: true,
      previewImage: "",
      key: "graphics-logos",
    },
    {
      name: "Leaflets / Business Cards",
      description: "",
      shortList: true,
      previewImage: "",
      key: "graphics-cards",
    },
    {
      name: "Wedding/Event Cards",
      description:
        "Custom Designed Physical & Virtual Cards, Generated with any number of names!",
      previewImage: "graphicDesign3",
      key: "leaflet",
    },
    {
      name: "Graphic Design",
      description:
        "Our talented designers create visually appealing logos, banners, animations, and social media posts to strengthen your brand identity. We deliver production-ready assets in vector formats.",
      previewImage: "graphicDesign",
      key: "graphics",
    },
    {
      name: "Technical Support and Server Management",
      description:
        "We offer ongoing technical support and server management to keep your systems running smoothly. With experience managing Linux and Windows servers, we monitor and optimize performance.",
      previewImage: "management",
      key: "technicalSupport",
    },
    {
      name: "Scripting and Automation",
      description:
        "We create scripts and bots to automate repetitive tasks, saving you time and money. Our expertise includes Python, Javascript, PHP and more.",
      previewImage: "automation",
      key: "scripting",
    },
    {
      name: "Digital Marketing",
      description:
        "We offer digital marketing services like SEO optimization, social media management, email marketing, and paid ads management to grow your online presence.",
      previewImage: "digitalmarketing2",
      key: "marketing",
    },
  ],
  contactDetails: contactDetails,
  socials: [
    {
      social: "facebook",
      url: "https://www.facebook.com/forwardsafedesigns",
      targetBlank: true,
      contact: true,
    },
    {
      social: "instagram",
      url: "https://www.instagram.com/forwardsafedesigns/",
      targetBlank: true,
      contact: true,
    },
    {
      social: "twitter",
      url: "https://twitter.com/forwardsafe",
      targetBlank: true,
    },
    {
      social: "linkedin",
      url: "https://www.linkedin.com/company/forwardsafe-designs/",
      targetBlank: true,
    },
    // { social: "pinterest", url: "", targetBlank: true },
    // { social: "fb_messenger", url: "", targetBlank: true },
    // { social: "skype", url: "", targetBlank: true },
    // { social: "youtube", url: "", targetBlank: true },
    // { social: "meta", url: "", targetBlank: true },
    {
      social: "whatsapp",
      url: `https://wa.me/${contactDetails.number}`,
      targetBlank: true,
      contact: true,
    },
    // { social: "google_plus", url: "", targetBlank: true },
  ],
}

const getFeaturedItems = items =>
  items ? items.filter(i => i.featured === true) : []

const addServiceToItem = service => item => ({ ...item, service: service })

const getFeaturedPortfolio = portfolios =>
  portfolios.reduce((accumulator, { items, subTypes, ...service }) => {
    let vals = []
    if (items && items?.length > 0) {
      vals = getFeaturedItems(items).map(addServiceToItem(service))
    }
    if (subTypes && subTypes.length > 0) {
      vals = [
        ...vals,
        ...subTypes.map(({ items, ...service1 }) =>
          getFeaturedItems(items).map(addServiceToItem(service1))
        ),
      ]
    }
    return [...accumulator, ...vals]
  }, [])

export const featuredPortfolios = getFeaturedPortfolio(data.portfolio).flat()
export default data
