import React from "react"
import { Tick } from "../icons"
import data from "../../utils/data"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { urls } from "../../utils/constants"
const LinkWrap = ({ serviceKey, children }) => {
  if (!serviceKey) return children
  return (
    <AnchorLink
      className="flex flex-row "
      to={`${urls.portfolio}#${serviceKey}`}
    >
      {children}
    </AnchorLink>
  )
}

const ServiceDisplay = ({ large, shortlist, useLinks }) => {
  const P = large ? "h6" : "p"
  const services = shortlist
    ? data.services.filter(p => !p?.shortList || p.shortlist === false)
    : data.services
  return (
    <ul className="grid grid-cols-1 md:grid-cols-2 max-w-3xl gap-4 mt-2 mb-8">
      {services.map(({ name, previewImage, description, key }) => (
        <li
          key={name}
          className="text-left wow fadeInDown flex flex-row items-center justify-start drop-shadow-lg"
          data-wow-delay=".25s"
        >
          <LinkWrap serviceKey={useLinks && key}>
            <Tick className="text-green-400 mr-2" />
            <P className="font-semibold text-base md:text-xl">{name}</P>
          </LinkWrap>
        </li>
      ))}
    </ul>
  )
}

export default ServiceDisplay
