import IntroContent from "../components/home/IntroContent"
import React from "react"
const topAnchorId = "back-to-top-anchor"
// const uri = "http://private.forwardsafe.co.uk:8064";
const uri = "http://192.168.0.56:8072"
//const uri = "https://www.mosquekitchen.com/api"

export const urls = {
  home: "/",
  portfolio: "/portfolio/",
  services: "/services/",
  contact: "/contact/",
}

const pages = [
  {
    to: urls.home,
    // text:"Contact Us"
    // linkDisplay:
    // displayTitle: "About Us",
    title: "Home",
    description: "",
    layoutImage: "13",
    displayTitle: "Built To Perfection",
    displaySubtitle: "Custom Built & Designed to your Specification",
    displayComponent: IntroContent,
    carousel: [
      {
        bgImage: "7",
        title: "The Gold Standard",
        children: <IntroContent bgImage={7} />,
        customMobileNavPadding: "pt-0",
        wowTitleAnimation: "wow bounceInDown",
      },
      {
        bgImage: "10",
        title: "A Space of Magnificence",
        titleClass: "lightPurple",
        children: <IntroContent bgImage={13} />,
        criticalLoad: true,
        // wowTitleAnimation: "wow bounceInLeft",
      },
      {
        bgImage: "3",
        title: "Custom Built",
        titleClass: "yellow",
        children: <IntroContent bgImage={3} />,
        customMobileNavPadding: "pt-0",
        criticalLoad: true,
        // wowTitleAnimation: "wow bounceInDown",
      },
    ],
  },
  {
    to: urls.portfolio,
    // text:"Contact Us"
    // linkDisplay:
    // displayTitle: "About Us",
    title: "Portfolio",
    description: "",
    layoutImage: "10",
    displayTitle: "A Space of Magnificence",
    displaySubtitle: "Explore our Work - The possibilities are endless!",
    sectionProps: { customMobileNavPadding: "pb-0" },
    displayComponent: IntroContent,
    displayComponentProps: {
      shortlist: true,
      hideAction: true,
      useLinks: true,
    },
  },
  {
    to: urls.services,
    title: "Services",
    displayTitle: "Our Services",
    displaySubtitle: "Custom Built & Designed to your Specification",
    layoutImage: "3",
  },
  {
    to: urls.contact,
    title: "Contact",
  },
]

const constants = {
  uri: uri,
  gqlUri: `${uri}/graphql`,
  formatMediaUrl: url => `${uri}/media/${url}`,
  topAnchorId,

  genTopAnchorLink: path => `${path ?? ""}#${topAnchorId}`,
  navbarOffset: -64,
  navPadding: 64,
  seo: Object.assign({}, ...pages.map(({ to, ...x }) => ({ [to]: x }))),
  navLinks: pages
    .filter(p => !p?.hideFromMainNav)
    .map(page => ({
      to: page.to,
      title: page.title,
      text: page.linkDisplay ?? page.title,
    })),
  navDrawerLinks: pages
    // .filter(p => !p?.hideFromMainNav)
    .map(page => ({
      to: page.to,
      title: page.title,
      text: page.linkDisplay ?? page.title,
    })),
}
export default constants
