import React from "react"

import { LongRightArrow, LongLeftArrow } from "../icons"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { urls } from "../../utils/constants"
import ServiceDisplay from "../services/ServiceDisplay"
const Inner = ({ bgImage, shortlist, hideAction, useLinks }) => {
  switch (bgImage) {
    case 13:
      // green background image
      return (
        <>
          <ServiceDisplay large shortlist={shortlist} useLinks={useLinks} />
          {!hideAction && (
            <div className="flex w-full space-x-3 mb-8 max-w-lg">
              <AnchorLink
                to={urls.services}
                className="flex-1 button outlined yellow"
              >
                Our Services <LongLeftArrow className="w-6 h-6" />
              </AnchorLink>
              <AnchorLink
                to={urls.portfolio}
                className="button flex-1 filled lightPurple"
              >
                <LongRightArrow className="w-6 h-6" />
                Our Portfolio
              </AnchorLink>
            </div>
          )}
        </>
      )
    case 7:
      // black background image
      return (
        <div
          className="flex w-full space-x-3 mb-8 max-w-lg wow bounceInUp"
          data-wow-delay=".5s"
        >
          <AnchorLink to={urls.contact} className="flex-1 button filled">
            Get in Touch
          </AnchorLink>
          <AnchorLink
            to={urls.portfolio}
            className="button flex-1 outlined green"
          >
            Our Portfolio
            <LongRightArrow className="w-6 h-6" />
          </AnchorLink>
        </div>
      )
    // case 3:
    default:
      // red blue background image
      return (
        <div className="flex w-full space-x-3 mb-8 max-w-lg">
          <AnchorLink to={urls.contact} className="button flex-1 filled yellow">
            Get In Touch
            <LongRightArrow className="w-6 h-6" />
          </AnchorLink>
          {/* <AnchorLink to={urls.contact} className="flex-1 button outlined">
            Contact Us
          </AnchorLink> */}
        </div>
      )
  }
}

const IntroContent = ({ bgImage = 13, ...props }) => {
  return (
    <div className="w-full flex flex-col items-center">
      <Inner bgImage={bgImage} {...props} />
    </div>
  )
}

export default IntroContent
